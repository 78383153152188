// @ts-nocheck
import React, { ReactNode } from 'react';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@inertiajs/react';

type ButtonVariant =
  | 'destructive'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'transparent';

export type ButtonProps = {
  as?: 'a' | 'button' | typeof Link;
  badge?: boolean;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  href?: string;
  icon?: IconName;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  pill?: boolean;
  target?: '_blank';
  type?: 'submit' | 'reset';
  variant: ButtonVariant;
};

const Button: React.FC<ButtonProps> = ({
  children,
  as: Component = 'button',
  badge = false,
  className = null,
  icon = null,
  pill = false,
  variant = 'secondary',
  ...props
}) => {
  const colors = {
    destructive:
      'bg-red-500 text-white hover:bg-red-400 disabled:hover:bg-red-500',
    primary:
      'bg-purple-500 text-white hover:bg-purple-400 disabled:hover:bg-purple-500',
    secondary:
      'bg-gray-50 text-gray-900 hover:bg-gray-100 disabled:hover:bg-gray-50',
    tertiary:
      'bg-gray-100 text-gray-900 hover:bg-gray-200 disabled:hover:bg-gray-100',
    transparent:
      'bg-transparent text-gray-900 hover:bg-gray-50 disabled:hover:bg-transparent',
  };

  const iconMarginR: string = children ? 'mr-1' : '';
  const paddingX = children ? 'px-2' : '';
  const roundedFull = pill && !children ? 'rounded-full' : '';

  return (
    <Component
      className={`relative inline-flex max-w-full items-center justify-center rounded font-medium text-base h-5 min-w-5 focus:outline-none focus:ring-2 focus:ring-purple-500 text-nowrap cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 ${colors[variant]} ${className} ${paddingX} ${roundedFull} `}
      {...props}
    >
      {icon && (
        <FontAwesomeIcon
          className={`w-2 h-2 text-normal ${iconMarginR}`}
          icon={['far', icon]}
        />
      )}
      {children}
      {badge && (
        <span className="absolute top-1.5 right-1.5 block h-1 w-1 rounded-full bg-red-600" />
      )}
    </Component>
  );
};

export default Button;
